import React from 'react'
import type { FC } from 'react'
import { Box, Flex, Text, Image } from '@vtex/store-ui'
import { Hidden } from 'src/components/ui/Hidden'

import styles from './styles.json'

interface Props {
  data: any
}

const FirstSectionAbove: FC<Props> = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.wrapper.leftContent}>
          <Box sx={styles.wrapper.leftContent.textContainer}>
            <Box sx={styles.wrapper.leftContent.textContainer.title}>
              <Text as="h2">
                Olha tudo o que a Linha Tratamake tem de incrível:
              </Text>
            </Box>
            <Box sx={styles.wrapper.leftContent.textContainer.listContainer}>
              <Box as="ul">
                <Box as="li">Ativos que tratam a pele</Box>
                <Box as="li">Paleta de cores para todas as peles e gostos</Box>
              </Box>
              <Box as="ul">
                <Box as="li">Ingredientes hidratantes</Box>
                <Box as="li">Proteção solar</Box>
              </Box>
            </Box>
            <Box sx={styles.wrapper.leftContent.textContainer.subtitle}>
              <Text as="h3">
                Hidrata os lábios mais ressecados e reduzem as linhas finas.
              </Text>
            </Box>
            <Box sx={styles.wrapper.leftContent.textContainer.description}>
              <Text as="h4">
                Cor, efeito matte e hidratação assim você{' '}
                <strong>nunca viu!</strong>
              </Text>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.wrapper.rightContent}>
          <Flex sx={styles.wrapper.rightContent.container}>
            <Box sx={styles.wrapper.rightContent.imageContainer}>
              <Hidden device="desktop">
                <Image
                  src={
                    data.cmsInstitutionalPage.sections[0].props.mobile.srcSet
                  }
                  alt={data.cmsInstitutionalPage.sections[0].props.alt}
                  title={data.cmsInstitutionalPage.sections[0].props.title}
                />
              </Hidden>
              <Hidden device="mobile">
                <Image
                  src={
                    data.cmsInstitutionalPage.sections[0].props.desktop.srcSet
                  }
                  alt={data.cmsInstitutionalPage.sections[0].props.alt}
                  title={data.cmsInstitutionalPage.sections[0].props.title}
                />
              </Hidden>
            </Box>
            <Box sx={styles.wrapper.rightContent.textGroup}>
              <Box>
                <Text as="p">
                  <strong>Resultados imediatos e também a longo prazo:</strong>
                </Text>
                <Text as="p">
                  Benefícios que continuam agindo mesmo após retirar a make!
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default FirstSectionAbove
