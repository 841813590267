import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import FirstSectionAbove from 'src/components/avon-tratamake/above/FirstSection'
import SecondSectionAbove from 'src/components/avon-tratamake/above/SecondSection'
import ThirdSectionAbove from 'src/components/avon-tratamake/above/ThirdSection'
import FourthSectionAbove from 'src/components/avon-tratamake/above/FourthSection'
import type { BatomHydramaticQueryQuery } from 'src/pages/avon-tratamake/__generated__/BatomHydramaticQuery.graphql'

type Props = PageProps<BatomHydramaticQueryQuery>

const Above: FC<Props> = ({ data }) => (
  <Box sx={{ overflow: ['initial', 'hidden'] }}>
    <EventPageView
      type="OTHER"
      title={data?.cmsInstitutionalPage?.seo?.siteMetadataWithSlug?.title}
    />
    <FirstSectionAbove data={data} />
    <SecondSectionAbove data={data} />
    <ThirdSectionAbove data={data} />
    <FourthSectionAbove data={data} />
  </Box>
)

export default Above
