import React from 'react'
import type { FC } from 'react'
import { Box, Flex, Text, Image } from '@vtex/store-ui'
import { Hidden } from 'src/components/ui/Hidden'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import {
  LipstickIcon,
  OutlinedHeartIcon,
  MouthIcon,
  HydrationIcon,
} from 'src/components/icons'

import styles from './styles.json'

interface Props {
  data: any
}

const ThirdSectionAbove: FC<Props> = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.wrapper.title}>
          <Text as="h3">Olha só como ele é poderoso:</Text>
          <Text as="p">
            Poderosa combinação de{' '}
            <strong>ácido hialurônico e ativos que hidratam os lábios!</strong>
          </Text>
        </Box>
        <Box sx={styles.wrapper.benefits}>
          <Flex sx={styles.wrapper.benefits.content}>
            <Box sx={styles.wrapper.benefits.item}>
              <Box as="span">
                <LipstickIcon />
              </Box>
              <Text as="h5">Vocês pediram e a Avon fez</Text>
              <Text as="p">
                É o batom dos sonhos: não desbota, não resseca e cuida dos
                lábios durante e após o uso.
              </Text>
            </Box>
            <Box sx={styles.wrapper.benefits.item}>
              <Box as="span">
                <OutlinedHeartIcon />
              </Box>
              <Text as="h5">Batom matte que hidrata</Text>
              <Text as="p">
                Hidrata e preenche até os lábios mais ressecados, além de ter
                FPS.
              </Text>
            </Box>
          </Flex>
          <Flex sx={styles.wrapper.benefits.content}>
            <Box sx={styles.wrapper.benefits.item}>
              <Box as="span">
                <MouthIcon />
              </Box>
              <Text as="h5">12 cores modernas e vibrantes</Text>
              <Text as="p">
                Variedade que combina com todos os tons de pele e estilos de
                pessoas.
              </Text>
            </Box>
            <Box sx={styles.wrapper.benefits.item}>
              <Box as="span">
                <HydrationIcon />
              </Box>
              <Text as="h5">Hidratação até o fim do dia</Text>
              <Text as="p">
                9 em 10 mulheres sentiram os lábios hidratados no fim do dia.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box sx={styles.wrapper.shelfContainer}>
          <Hidden device="desktop">
            <Shelf
              {...data.cmsInstitutionalPage.sections[4].props}
              showArrows={false}
              ProductSummary={ProductSummary}
              pageSizes={[1]}
            />
          </Hidden>
        </Box>
        <Box sx={styles.wrapper.image}>
          <Hidden device="desktop">
            <Image
              src={data.cmsInstitutionalPage.sections[5].props.mobile.srcSet}
              alt={data.cmsInstitutionalPage.sections[5].props.alt}
              title={data.cmsInstitutionalPage.sections[5].props.title}
            />
          </Hidden>
          <Hidden device="mobile">
            <Image
              src={data.cmsInstitutionalPage.sections[5].props.desktop.srcSet}
              alt={data.cmsInstitutionalPage.sections[5].props.alt}
              title={data.cmsInstitutionalPage.sections[5].props.title}
            />
          </Hidden>
        </Box>
      </Box>
    </Box>
  )
}

export default ThirdSectionAbove
