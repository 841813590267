import React from 'react'
import type { FC } from 'react'
import { Box, Image, Text, SuspenseSSR } from '@vtex/store-ui'
import { Hidden } from 'src/components/ui/Hidden'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'

import styles from './styles.json'

interface Props {
  data: any
}

const SecondSectionAbove: FC<Props> = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.content}>
          <Box sx={styles.content.thumbnailsContainer}>
            <Box sx={styles.content.thumbnailsContainer.content}>
              <Box sx={styles.content.thumbnailsContainer.image}>
                <Hidden device="desktop">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[1].props.mobile.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[1].props.alt}
                    title={data.cmsInstitutionalPage.sections[1].props.title}
                  />
                </Hidden>
                <Hidden device="mobile">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[1].props.desktop.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[1].props.alt}
                    title={data.cmsInstitutionalPage.sections[1].props.title}
                  />
                </Hidden>
              </Box>
              <Box sx={styles.content.thumbnailsContainer.texts}>
                <Text as="h4">
                  {data.cmsInstitutionalPage.sections[1].props.title}
                </Text>
                <Text as="p">
                  {data.cmsInstitutionalPage.sections[1].props.description}
                </Text>
              </Box>
            </Box>
            <Box sx={styles.content.thumbnailsContainer.content}>
              <Box sx={styles.content.thumbnailsContainer.image}>
                <Hidden device="desktop">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[2].props.mobile.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[2].props.alt}
                    title={data.cmsInstitutionalPage.sections[2].props.title}
                  />
                </Hidden>
                <Hidden device="mobile">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[2].props.desktop.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[2].props.alt}
                    title={data.cmsInstitutionalPage.sections[2].props.title}
                  />
                </Hidden>
              </Box>
              <Box sx={styles.content.thumbnailsContainer.texts}>
                <Text as="h4">
                  {data.cmsInstitutionalPage.sections[2].props.title}
                </Text>
                <Text as="p">
                  {data.cmsInstitutionalPage.sections[2].props.description}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.content.textContainer}>
            <Box sx={styles.content.textContainer.image}>
              <Image
                src={data.cmsInstitutionalPage.sections[3].props.mobile.srcSet}
                alt={data.cmsInstitutionalPage.sections[3].props.alt}
                title={data.cmsInstitutionalPage.sections[3].props.title}
              />
            </Box>
            <Box sx={styles.content.textContainer.title}>
              <Text as="h3">
                <span>
                  <strong>Tratamake</strong> é batom matte com tratamento por
                  dentro e cobertura total por fora.
                </span>
              </Text>
            </Box>
          </Box>
          <Box sx={styles.content.shelfContainer}>
            <Hidden device="mobile">
              <Box sx={styles.content.shelfContainer.image}>
                <Hidden device="desktop">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[3].props.mobile.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[3].props.alt}
                    title={data.cmsInstitutionalPage.sections[3].props.title}
                  />
                </Hidden>
                <Hidden device="mobile">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[3].props.desktop.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[3].props.alt}
                    title={data.cmsInstitutionalPage.sections[3].props.title}
                  />
                </Hidden>
              </Box>
            </Hidden>
            <Hidden device="mobile">
              <Box sx={styles.content.shelfContainer.shelf}>
                <SuspenseSSR fallback={null}>
                  <Shelf
                    {...data.cmsInstitutionalPage.sections[4].props}
                    showArrows={false}
                    ProductSummary={ProductSummary}
                    pageSizes={[1]}
                  />
                </SuspenseSSR>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SecondSectionAbove
