import React from 'react'
import type { FC } from 'react'
import { Box, Flex, Text, Image } from '@vtex/store-ui'
import { Hidden } from 'src/components/ui/Hidden'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'

import styles from './styles.json'

interface Props {
  data: any
}

const FourthSectionAbove: FC<Props> = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Flex sx={styles.content}>
          <Box sx={styles.image}>
            <Hidden device="desktop">
              <Image
                src={data.cmsInstitutionalPage.sections[6].props.mobile.srcSet}
                alt={data.cmsInstitutionalPage.sections[6].props.alt}
                title={data.cmsInstitutionalPage.sections[6].props.title}
              />
            </Hidden>
            <Hidden device="mobile">
              <Image
                src={data.cmsInstitutionalPage.sections[6].props.desktop.srcSet}
                alt={data.cmsInstitutionalPage.sections[6].props.alt}
                title={data.cmsInstitutionalPage.sections[6].props.title}
              />
            </Hidden>
          </Box>
          <Box sx={styles.productContent}>
            <Flex sx={styles.beforeAndAfter}>
              <Box sx={styles.beforeAndAfter.image}>
                <Hidden device="desktop">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[7].props.mobile.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[7].props.alt}
                    title={data.cmsInstitutionalPage.sections[7].props.title}
                  />
                </Hidden>
                <Hidden device="mobile">
                  <Image
                    src={
                      data.cmsInstitutionalPage.sections[7].props.desktop.srcSet
                    }
                    alt={data.cmsInstitutionalPage.sections[7].props.alt}
                    title={data.cmsInstitutionalPage.sections[7].props.title}
                  />
                </Hidden>
              </Box>
              <Box sx={styles.beforeAndAfter.title}>
                <Text as="h4">
                  <strong>Tratamake</strong> é a base que uniformiza a pele na
                  hora e segue tratando depois.
                </Text>
              </Box>
            </Flex>
            <Flex sx={styles.shelfContainer}>
              <Box sx={styles.shelfContainer.texts}>
                <Hidden device="desktop">
                  <Flex sx={styles.shelfContainer.benefits}>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/vitamin-e-icon.png"
                        alt="Vitamina E hidrata a pele e combate os radicais livres"
                        title="Vitamina E hidrata a pele e combate os radicais livres"
                      />
                      <Text as="h5">Vitamina E:</Text>
                      <Text as="p">
                        hidrata a pele e combate os radicais livres
                      </Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/serum-icon.png"
                        alt="Textura Sérum que hidrata"
                        title="Textura Sérum que hidrata"
                      />
                      <Text as="h5">Textura Sérum</Text>
                      <Text as="p">que hidrata</Text>
                    </Box>
                  </Flex>
                  <Flex sx={styles.shelfContainer.benefits}>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-makeup-icon.png"
                        alt="Acabamento radiante e natural com 15 tons"
                        title="Acabamento radiante e natural com 15 tons"
                      />
                      <Text as="h5">Acabamento</Text>
                      <Text as="p">radiante e natural com 15 tons</Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/electron-icon.png"
                        alt="Ácido Tiodipropiônico auxilia na uniformização do tom da pele com o uso
                        contínuo"
                        title="Ácido Tiodipropiônico auxilia na uniformização do tom da pele com o uso
                        contínuo"
                      />
                      <Text as="h5">Ácido Tiodipropiônico</Text>
                      <Text as="p">
                        auxilia na uniformização do tom da pele com o uso
                        contínuo
                      </Text>
                    </Box>
                  </Flex>
                  <Flex sx={styles.shelfContainer.benefits}>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-face-protection-icon.png"
                        alt="Microesferas de sílica ajudam a absorver a oleosidade da pele"
                        title="Microesferas de sílica ajudam a absorver a oleosidade da pele"
                      />
                      <Text as="h5">Microesferas de sílica:</Text>
                      <Text as="p">ajudam a absorver a oleosidade da pele</Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-wash-icon.png"
                        alt="Melhora a aparência da pele de imediato e com o uso contínuo, mesmo após
                        retirar a make"
                        title="Melhora a aparência da pele de imediato e com o uso contínuo, mesmo após
                        retirar a make"
                      />
                      <Text as="h5">Melhora a aparência</Text>
                      <Text as="p">
                        da pele de imediato e com o uso contínuo, mesmo após
                        retirar a make
                      </Text>
                    </Box>
                  </Flex>
                </Hidden>
                <Hidden device="mobile">
                  <Flex sx={styles.shelfContainer.benefits}>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/vitamin-e-icon.png"
                        alt="Vitamina E hidrata a pele e combate radicais livres"
                        title="Vitamina E hidrata a pele e combate radicais livres"
                      />
                      <Text as="h5">Vitamina E:</Text>
                      <Text as="p">
                        hidrata a pele e combate os radicais livres
                      </Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/serum-icon.png"
                        alt="Textura Sérum que hidrata"
                        title="Textura Sérum que hidrata"
                      />
                      <Text as="h5">Textura Sérum</Text>
                      <Text as="p">que hidrata</Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-makeup-icon.png"
                        alt="Acabamento radiante e natural com 15 tons"
                        title="Acabamento radiante e natural com 15 tons"
                      />
                      <Text as="h5">Acabamento</Text>
                      <Text as="p">radiante e natural com 15 tons</Text>
                    </Box>
                  </Flex>
                  <Flex sx={styles.shelfContainer.benefits}>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/electron-icon.png"
                        alt="Ácido Tiodipropiônico auxilia na uniformização do tom da pele com o uso contínuo"
                        title="Ácido Tiodipropiônico auxilia na uniformização do tom da pele com o uso contínuo"
                      />
                      <Text as="h5">Ácido Tiodipropiônico</Text>
                      <Text as="p">
                        auxilia na uniformização do tom da pele com o uso
                        contínuo
                      </Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-face-protection-icon.png"
                        alt="Microesferas de sílica ajudam a absorver a oleosidade da pele"
                        title="Microesferas de sílica ajudam a absorver a oleosidade da pele"
                      />
                      <Text as="h5">Microesferas de sílica:</Text>
                      <Text as="p">ajudam a absorver a oleosidade da pele</Text>
                    </Box>
                    <Box sx={styles.shelfContainer.benefits.item}>
                      <Image
                        src="http://avongroup.vteximg.com.br/arquivos/women-wash-icon.png"
                        alt="Melhora a aparência da pele de imediato e com o uso contínuo, mesmo após retirar a make"
                        title="Melhora a aparência da pele de imediato e com o uso contínuo, mesmo após retirar a make"
                      />
                      <Text as="h5">Melhora a aparência</Text>
                      <Text as="p">
                        da pele de imediato e com o uso contínuo, mesmo após
                        retirar a make
                      </Text>
                    </Box>
                  </Flex>
                </Hidden>
              </Box>
              <Box sx={styles.shelfContainer.shelf}>
                <Shelf
                  {...data.cmsInstitutionalPage.sections[8].props}
                  showArrows={false}
                  ProductSummary={ProductSummary}
                  pageSizes={[1]}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default FourthSectionAbove
